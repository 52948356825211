<template>
  <div class="common-problem">
    <div class="content-wrapper" ref="content">
      <!-- <scroll-list
        class="card-box"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        :data="data"
        :getData="getData">
        <div class="news-wrapper border-bottom-1px" v-for="item in data" :key="item.idCcNewsManage" @click="toNewsDetail(item)">
          <div class="news-info-wrapper">
            <h4>{{item.newsTitle}}</h4>
            <div class="img-list" v-if="item.coverUrlArr.length > 1">
              <img v-for="(img, index) in item.coverUrlArr" v-lazy="img" :key="index" alt="">
            </div>
            <p><span>{{item.publishTime}}</span><span>{{item.newsPromulgator}}</span></p>
          </div>
          <img v-if="item.coverUrlArr.length === 1" v-lazy="item.coverUrlArr[0]" alt="">
        </div>
      </scroll-list> -->
      <div class="card-box">
        <ListGroup
          ref="List"
          :config="config"
        ></ListGroup>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
import ListGroup from '@/components/list-group/index.js'

const { getProblemList } = API
export default {
  components: {
    // 'scroll-list': () => import('@/components/ScrollList.vue')
    ListGroup
  },
  data () {
    return {
      data: [],
      pageSize: 10,
      config: {
        getList: (params) => this.getData(params, ''),
        render: (item) => this.renderItem(item)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.contentScrollTop = this.$refs.content.scrollTop
    next()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$refs.content.scrollTop = vm.contentScrollTop
    })
  },
  methods: {
    renderItem (item) {
      return (
        <div class="news-wrapper border-bottom-1px" key={ item.idCcNewsManage } on-click={ () => this.toNewsDetail(item) }>
          {
            !item.questionModel && <img class="img-one" v-lazy={ item.coverUrlArr[0] } alt="" />
          }
          <div class="news-info-wrapper">
            <h4>{ item.questionTitle }</h4>
            {
              item.questionModel
                ? <div class="img-list">
                  {
                    item.coverUrlArr.map(img => {
                      return <img class="img-more" v-lazy={img} key={ img } alt="" />
                    })
                  }
                </div>
                : null
            }
            <p><span>{ item.publishTime }</span><span>{ item.newsPromulgator }</span></p>
          </div>
        </div>
      )
    },
    async getData ({ page }) {
      return new Promise((resolve, reject) => {
        getProblemList({
          start: page,
          limit: this.pageSize,
          questionStatus: 3
        }).then(({ data, success, message, totalCount }) => {
          if (success) {
            resolve({
              list: data.map(item => {
                return {
                  ...item,
                  coverUrlArr: (item.questionCoverUrls || '').split(';')
                }
              }),
              total: totalCount
            })
          } else {
            throw new Error(message)
          }
        })
      })
    },
    toNewsDetail (data) {
      this.$router.push({
        name: 'problemDetail',
        params: {
          id: data.id || 0
        }
      })
    }
  }
}
</script>

<style lang="scss">
.common-problem{
  .news-wrapper{
    display: flex;
    padding-bottom: 28px;
    margin-bottom: 28px;
    .news-info-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      h4{
        font-size: 28px;
        color: #333;
        font-weight: 500;
        margin-bottom: 24px;
      }
      p{
        font-size: 24px;
        color: #999999;
        span:last-child{
          margin-left: 18px;
        }
      }
      .img-list{
        display: flex;
        margin-bottom: 14px;
        .img-more{
          width: 206px;
          height: 114px;
          &:not(:first-child){
            margin-left: 18px;
          }
        }
      }
    }

    .img-one{
      width: 112px;
      height: 112px;
      margin-right: 16px;
    }
  }
}
</style>
